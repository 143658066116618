*, ::after, ::before {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

button.busyButton {
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .user-container {
    width: 25%;
    padding: 0 10px;
    text-align: center;
  }
}

.user-container {
  margin-bottom: 15px;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 5%);
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.user-container.offline .status {
  background-color: rgba(239,154,154,.8);
}

.user-container.online .status {
  background-color: rgba(200,230,201,.8);
}

.user-container.online .status .dot {
  background-color: #388e3c;
}

.user-container.offline .status .dot {
  background-color: #d32f2f;
}

.user-container .status .dot {
  height: 13px;
  width: 13px;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.user-container .status {
  display: flex;
  align-items: center;
  left: 20px;
  top: 15px;
  padding: 6px 10px;
  border-radius: 20px;
  position: absolute;
}

.user-container .avatar {
  border-radius: 0;
  width: 100%;
  height: 180px;
  overflow: hidden;
  line-height: 0;
  margin-bottom: 20px;
}

.user-container .avatar img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
img {
  overflow: hidden;
  display: inherit;
}
img {
  vertical-align: middle;
}
img {
  border: 0;
}